/* ./src/index.css */
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
}
main {
  min-height: 80vh;
}
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}
h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
h3 {
  padding: 1rem 0;
}
.rating span {
  margin: 0.1rem;
  font-size: 0.9em;
}
/* carousel */
.carousel-item {
  height: 65vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
  padding: 10px;
  box-sizing: border-box;
}

.carousel-caption h3 {
  color: #fff;
}

.carousel-caption p {
  color: white;
  margin-top: 53%;
}

/* .carousel img {
  height: auto;
  width: 50%;
  padding: 40px;
  margin: 40px;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
} */
.carousel a {
  margin: 0 auto;
}

@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}
